<mat-card class="character-levels" *ngIf="levels$ | async">
  <mat-card-title fxLayout="row" fxLayoutAlign="center center">
    <div>Character</div>
    <div fxFlex></div>
    <div><button mat-raised-button color="warn" (click)="onCharacterReset()">Reset</button></div>
  </mat-card-title>
  <mat-card-content fxLayout="column" fxLayoutGap="10px">
    <div *ngFor="let level of levels$ | async; let i = index" fxLayout="row" fxLayoutAlign="space-between">
      <div>Level {{ i + 1 }}</div>
      <div>{{ level.class.name }} {{ level.classLevel }}</div>
      <div *ngIf="level.subclass">{{ level.subclass.shortName }}</div>
    </div>
  </mat-card-content>
</mat-card>