import { Pipe, PipeTransform } from '@angular/core'
import { Filter } from '../states/homepage/homepage.model'

@Pipe({
  name: 'sectionVisible',
  pure: true
})
export class SectionVisiblePipe implements PipeTransform {
  transform(filters: Array<Filter>, id: string): boolean {
    const filter = filters.find(f => f.id === id)
    return filter ? filter.active : true
  }
}
