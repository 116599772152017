<mat-card class="class-picker">
  <mat-card-title>Pick a Class</mat-card-title>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="5px">
      <mat-form-field fxFlex>
        <mat-select placeholder="Available Classes" (selectionChange)="onClassSelected($event.value)">
          <mat-option *ngFor="let validClass of availableClasses" [value]="validClass">{{ validClass.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="selectedClass" fxFlex>
        <mat-select placeholder="Levels" [(ngModel)]="levelsToAdd">
          <mat-option *ngFor="let arr of [].constructor(20); let level = index" [value]="level + 1">{{ level + 1 }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="validClasses && validClasses.length > 1">
        <mat-form-field fxFlex>
          <mat-select placeholder="Class Source" [(ngModel)]="characterClass">
            <mat-option *ngFor="let validClass of validClasses" [value]="validClasses">{{ validClass.source }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="characterClass && validSubClasses">
        <mat-form-field fxFlex>
          <mat-select placeholder="Subclass" [(ngModel)]="characterSubClass">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let subclass of validSubClasses" [value]="subclass">{{ subclass.shortName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="this.characterAddError.length > 0" class="alert alert-danger">
      <div *ngFor="let error of this.characterAddError">
        {{ error }}
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions fxLayout="row" *ngIf="characterClass">
    <div fxFlex></div>
    <div><button mat-raised-button (click)="onAddClass()">Add Class</button></div>
  </mat-card-actions>
</mat-card>