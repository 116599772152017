<mat-card class="level-card">
  <mat-card-title class="level-title">
    Level {{ levelIndex + 1 }}: {{ level.class.name }} {{ level.subclass ? level.subclass.name : ''}}
  </mat-card-title>
  <mat-card-content *ngIf="displayFeatures.length > 0; else noFeatures">
    <div *ngFor="let feature of displayFeatures" fxLayout="column" fxLayoutGap="5px">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="feature-name">{{ feature.name }}</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="feature-entry" *ngFor="let entry of feature.entries">
          <div *ngIf="entry.type; else stringEntry">
            <ul class="feature-list" *ngIf="entry.type === 'list'">
              <li *ngFor="let item of entry.items">
                {{ item }}
              </li>
            </ul>
            <ul class="feature-list" *ngIf="entry.type === 'entries'">
              <li>
                <div>{{ entry.name }}</div>
                <div *ngFor="let subentry of entry.entries">
                  {{ subentry }}
                </div>
              </li>
            </ul>
          </div>
          <ng-template #stringEntry>
            <div>{{ entry }}</div>
          </ng-template>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-card-content>
  <ng-template #noFeatures>
    <mat-card-content>
      This class has no features at this level.
    </mat-card-content>
  </ng-template>
  <mat-card-footer *ngIf="missingSubclass" fxLayout="column" fxLayoutGap="10px">
    <div class="alert alert-danger">Missing Subclass</div>
  </mat-card-footer>
</mat-card>