import { AbilityScoreComponent } from './character-builder/ability-score/ability-score.component'
import { AllowedMaterialComponent } from './allowed-material/allowed-material.component'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFireModule } from '@angular/fire'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { CastingServiceCalculatorComponent } from './casting-service-calculator/casting-service-calculator.component'
import { CharacterBuilderComponent } from './character-builder/character-builder.component'
import { CharacterBuilderState } from './states/character-builder/charter-builder.state'
import { CharacterInfoComponent } from './character-info/character-info.component'
import { CharacterLevelsComponent } from './character-builder/character-levels/character-levels.component'
import { ClassPickerComponent } from './character-builder/class-picker/class-picker.component'
import { DmLandingComponent } from './dm-landing/dm-landing.component'
import { DmState } from './states/dm/dm.state'
import { DonatedMaterialComponent } from './donated-material/donated-material.component'
import { DowntimeComponent } from './downtime/downtime.component'
import { environment } from '../environments/environment'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HomepageComponent } from './homepage/homepage.component'
import { HomepageState } from './states/homepage/homepage.state'
import { HpCalculatorComponent } from './hp-calculator/hp-calculator.component'
import { LevelCardComponent } from './character-builder/level-config/level-card/level-card.component'
import { LevelConfigComponent } from './character-builder/level-config/level-config.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule} from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsResetPluginModule } from 'ngxs-reset-plugin'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { NgxTrimDirectiveModule } from 'ngx-trim-directive'
import { RacePickerComponent } from './character-builder/race-picker/race-picker.component'
import { RulesComponent } from './rules/rules.component'
import { SectionVisiblePipe } from './pipes/section-visible.pipe'
import { ServerRulesComponent } from './server-rules/server-rules.component'
import { SpellPickerComponent } from './character-builder/spell-picker/spell-picker.component'
import { TiersComponent } from './tiers/tiers.component'
import { FaqComponent } from './faq/faq.component'
import { FaqDialogComponent } from './faq-dialog/faq-dialog.component'
import { FlankingDialogComponent } from './rules/flanking-dialog/flanking-dialog.component'
import { AuthModule } from '@auth0/auth0-angular';
import { LootGeneratorComponent } from './loot-generator/loot-generator.component'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
  declarations: [
    AbilityScoreComponent,
    AllowedMaterialComponent,
    AppComponent,
    CharacterBuilderComponent,
    CharacterLevelsComponent,
    ClassPickerComponent,
    DonatedMaterialComponent,
    HomepageComponent,
    HpCalculatorComponent,
    LevelCardComponent,
    LevelConfigComponent,
    RacePickerComponent,
    RulesComponent,
    TiersComponent,
    SpellPickerComponent,
    ServerRulesComponent,
    SectionVisiblePipe,
    CharacterInfoComponent,
    DowntimeComponent,
    CastingServiceCalculatorComponent,
    DmLandingComponent,
    FaqComponent,
    FaqDialogComponent,
    FlankingDialogComponent,
    LootGeneratorComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatSidenavModule,
    ReactiveFormsModule,
    NgxsModule.forRoot([
      CharacterBuilderState,
      DmState,
      HomepageState
    ], {
      developmentMode: false,
      selectorOptions: { // NGXS v4 Future Proofing
        suppressErrors: false,
        injectContainerState: false,
      }
    }),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    NgxTrimDirectiveModule,
    AuthModule.forRoot({
      domain: 'ruthgars.us.auth0.com',
      clientId: 'FADweQKdJavETEJRa9ZJdVGrR5hWdc79'
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
