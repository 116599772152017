<div class="dm-landing" fxLayout="row" fxLayoutGap="10px">
  <div fxLayout="column" fxLayoutGap="10px">
    <mat-card class="card">
      <mat-card-title>Login</mat-card-title>
      <mat-card-content fxLayout="column" fxLayoutGap="10px" *ngIf="!isLoggedIn">
        <mat-form-field>
          <input matInput type="email" [formControl]="emailFormControl" placeholder="Email">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">Valid email required.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" [formControl]="passwordFormControl" placeholder="Password">
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlight="end start">
        <div fxFlex></div>
        <button *ngIf="!isLoggedIn" mat-raised-button color="primary" (click)="onLogin()" [disabled]="emailFormControl.invalid || passwordFormControl.invalid">Login</button>
        <button *ngIf="isLoggedIn" mat-raised-button color="primary" (click)="onLogout()">Logout</button>
      </mat-card-actions>
    </mat-card>
    <mat-card class="card" *ngIf="isLoggedIn">
      <mat-card-title>FAQ Addition</mat-card-title>
      <mat-card-content>
        <mat-form-field fxFill>
          <input matInput type="text" [formControl]="questionFormControl" placeholder="Question">
        </mat-form-field>
        <mat-form-field fxFill>
          <textarea  matInput type="text" [formControl]="answerFormControl" placeholder="A long answer here"></textarea>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="warn" (click)="onAddToFAQ()">Add New FAQ</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <app-faq></app-faq>
</div>