<mat-card class="card">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title>Downtime - Totally Optional but Beneficial</mat-card-title>
    <mat-card-content fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
        <h3>General Rules</h3>
        <div>
          <div>Each week of play earns 5 downtime days (1 downtime week).</div>
          <div>No downtime activities between characters owned by the same player.</div>
          <div>If no DC is provided for an activity, the base is set to 15 and a relevant check.</div>
          <div>Complications do not need to be rolled.</div>
        </div>
        <h3>Downtime Lifestyle</h3>
        <div class="section" fxLayout="column" fxLayoutGap="5px">
          <div>
            <div>Downtime activities and lifestyle maintenance occur outside the scope of adventures you play, and can have an impact on how others perceive your character.</div>
            <div>If you want your character to engage in a downtime activity between missions, you have several options available to you. </div>
            <div>You can see all your options in Downtime from <a href="https://5etools.ruthgars.com/variantrules.html#downtime%20revisited_xge">Xanther’s Guide to Everything</a>.</div>
          </div>
          <div>
            <div class="note">Note: Some activities listed are irrelevant. Buying magic items must follow #shop rules (no need to roll for item tables) - see Buying and Selling Items. There is no Favor system, see Religious Services and Favors.</div>
          </div>
          </div>
        <div><hr></div>
        <h3>Earning Downtime</h3>
        <div class="section" fxLayout="column" fxLayoutGap="5px">
          <div>
            <div>A character gains 5 downtime days for participating in a session.</div>
            <div>A character can gain a maximum of 5 downtime days once per Real Life week.</div>
            <div>There is no limit to how much downtime days stored.</div>
          </div>
          <div>
            <div class="note">Examples:</div>
            <div class="note">Cherry Allen plays in 1 session the week of 2020-06-12. He earns 1 downtime week.</div>
            <div class="note">Sonya plays 2 sessions the same week. She earns 1 downtime week.</div>
            <div class="note">Missile Jim plays 3 games that week. He earns 1 downtime week.</div>
          </div>
        </div>
        <div><hr></div>
        <h3>Recording Downtime</h3>
        <div class="section" fxLayout="column" fxLayoutGap="5px">
          <div>All downtime activity will be recorded in #downtime.</div>
          <div>Rolls for downtime activity will be posted as screenshots from the PUBLIC channel (do not use /talktome or another server) Ruthgar’s Roll20 game.</div>
        </div>
        <div><hr></div>
        <h3>Religious Services and Favors</h3>
        <div class="section" fxLayout="column" fxLayoutGap="5px">
          <div>Ruthgar’s Favor converts to Credit which is explained in <b>Buying and Selling Items (Credits, Haggling)</b>.</div>
          <div>Unlike Haggling, Religious Services can only be used once per item.</div>
          <div class="note">Example: You spend 1 work week and do an Intelligence (Religion check). You get a 20. You earn 1 Credit.</div>
        </div>
        <div><hr></div>
        <h3>Trading Spells</h3>
        <div class="section" fxLayout="column" fxLayoutGap="5px">
          <div>Two wizards can trade spells of equal level by spending 1 downtime day each and by spending the necessary gold for the level of that spell each.</div>
        </div>
        <div><hr></div>
        <h3>Carousing</h3>
        <div class="section">
          <div>When this activity is started, you will need to ping an @DM with the results of their Carousing. The DM will inform the player about what kind of contact they have met and possibly how they will interact.</div>
        </div>
        <div><hr></div>
        <h3>Relaxation</h3>
        <div class="section">
          <div>At the end of a relaxation downtime activity, a character will earn 1d10 TEMP HP.</div>
        </div>
      </div>
      <div fxFlex="50" fxLayout="column" fxLayoutGap="5px">
        <h3>Spell Casting Service</h3>
        <div class="section" fxLayout="column" fxLayoutGap="5px">
          <div>If you finish a mission and need a spell cast outside of combat, you can spend one downtime day and pay the cost of the spell to have an appropriate spell cast. Alternatively, another party member can provide the service.</div>
          <div>Both you and the spellcaster in your party spend one downtime day to have the spells cast. Anyone in the party can pay the cost for consumed material components for spells such as raise dead. If your character is raised from the dead during a mission, they can continue, but the penalties imposed by the raise dead spell apply.</div>
          <div class="note">COST: 60% of the cost of an equivalent spell scroll.</div>
        </div>
        <div><hr></div>
        <h3>Exchanging Wealth</h3>
        <div class="section">
          <div>You can’t give another character gold or items. However, you can pay for some or all the cost of services that help another character, such as the cost of a raise dead spell.</div>
        </div>
        <div><hr></div>
        <h3>Trading Items</h3>
        <div class="section">
          <div>Items cannot be traded between characters.</div>
        </div>
        <div><hr></div>
        <h3>Buying and Selling Items (Credits, Haggling)</h3>
        <div class="section" fxLayout="column" fxLayoutGap="5px">
          <div>The Ruthgar’s #store offers a DC15 Persuasion for all checks against it. You, as a member of Ruthgar’s, already get the best deal for items. Any better deal is harder. Selling an item starts at 80% of the sale price, while purchasing an item starts at 100% with a minimum of 80% (the sale price can never go below).</div>
          <div>Ruthgar's uses <b>Credits</b> to represent a change in price. Each 1 Credit is worth 5% of a specific item.</div>
          <div>For every 1 Downtime Day spent with a successful check, you earn 1 Credit. Regardless of success, each check increases DC by 5. For screenshot purposes, downtime must be spent BEFORE rolling (in chat).</div>
        </div>
        <div><hr></div>
        <h3>Crafting</h3>
        <div class="section" fxLayout="column" fxLayoutGap="10px">
          <div>Follow the Xanther’s Guide to Everything rules for cost and downtime days. The cost of crafting an item also includes 50% of the sale price in Ruthgar’s <code>#shop</code>.</div>
          <div>An exotic material takes 1 downtime week and a DC Intelligence (Arcana or History) or Wisdom (Animal Handling or Survival) check to acquire.</div>
          <div>The character makes three checks to craft the item: Intelligence (Arcana), Wisdom (Insight), and an appropriate Artisan's Tools check (ask a DM in <code>#5e-questions</code>).</div>
          <div><b>Common (DC15), Uncommon (DC20), Rare (DC25), Very Rare (DC30)</b></div>
          <div>If none of the checks are successful, the crafting has catastrophically failed, losing the exotic material and all costs in the process.</div>
          <div>If only one check is successful, the crafting fails but you keep the exotic material.</div>
          <div>If two checks are successful, the crafting fails but you keep the exotic material and any costs you put in.</div>
          <div>If all three checks are successful, the crafting is complete and the character gets the item.</div>
          <div>Items over 25,000GP, Wondrous, Legendary, and Artifact items cannot be crafted.</div>
        </div>
        <div><hr></div>
        <h3>Training and Character Progression</h3>
        <div class="section">
          <div>No downtime activity may change a player character's XP level, Ability Score, Proficiencies, or Skills.</div>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>
