import * as artificer from 'src/app/data/class/class-artificer.json'
import * as barbarian from 'src/app/data/class/class-barbarian.json'
import * as bard from 'src/app/data/class/class-bard.json'
import * as cleric from 'src/app/data/class/class-cleric.json'
import * as druid from 'src/app/data/class/class-druid.json'
import * as fighter from 'src/app/data/class/class-fighter.json'
import * as monk from 'src/app/data/class/class-monk.json'
import * as paladin from 'src/app/data/class/class-paladin.json'
import * as ranger from 'src/app/data/class/class-ranger.json'
import * as rogue from 'src/app/data/class/class-rogue.json'
import * as sorcerer from 'src/app/data/class/class-sorcerer.json'
import * as warlock from 'src/app/data/class/class-warlock.json'
import * as wizard from 'src/app/data/class/class-wizard.json'

export const VALID_CLASSES = [
  {
    name: 'Artificer',
    class: artificer.class,
    classFeature: artificer.classFeature,
    subclassFeature: artificer.subclassFeature,
  }, {
    name: 'Barbarian',
    class: barbarian.class,
    classFeature: barbarian.classFeature,
    subclassFeature: barbarian.subclassFeature,
  }, {
    name: 'Bard',
    class: bard.class,
    classFeature: bard.classFeature,
    subclassFeature: bard.subclassFeature,
  }, {
    name: 'Cleric',
    class: cleric.class,
    classFeature: cleric.classFeature,
    subclassFeature: cleric.subclassFeature,
  }, {
    name: 'Druid',
    class: druid.class,
    classFeature: druid.classFeature,
    subclassFeature: druid.subclassFeature,
  }, {
    name: 'Fighter',
    class: fighter.class,
    classFeature: fighter.classFeature,
    subclassFeature: fighter.subclassFeature,
  }, {
    name: 'Monk',
    class: monk.class,
    classFeature: monk.classFeature,
    subclassFeature: monk.subclassFeature,
  }, {
    name: 'Paladin',
    class: paladin.class,
    classFeature: paladin.classFeature,
    subclassFeature: paladin.subclassFeature,
  }, {
    name: 'Ranger',
    class: ranger.class,
    classFeature: ranger.classFeature,
    subclassFeature: ranger.subclassFeature,
  }, {
    name: 'Rogue',
    class: rogue.class,
    classFeature: rogue.classFeature,
    subclassFeature: rogue.subclassFeature,
  }, {
    name: 'Sorcerer',
    class: sorcerer.class,
    classFeature: sorcerer.classFeature,
    subclassFeature: sorcerer.subclassFeature,
  }, {
    name: 'Warlock',
    class: warlock.class,
    classFeature: warlock.classFeature,
    subclassFeature: warlock.subclassFeature,
  }, {
    name: 'Wizard',
    class: wizard.class,
    classFeature: wizard.classFeature,
    subclassFeature: wizard.subclassFeature,
  },
]
