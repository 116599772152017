import { CharacterBuilderStateModel, AbilityScore, Level } from './character-builder.model'
import { Injectable } from '@angular/core'
import { produce } from 'immer'
import { SetAbilityScore, SelectRace, CalculateFinalAbilityScore, AddRaceCustomAbility, SelectCharacterClass, ResetLevels } from './character-builder.actions'
import { State, Selector, Action, StateContext } from '@ngxs/store'
import { of } from 'rxjs'

@State({
  name: 'characterbuilder',
  defaults: {
    ability_score: {
      STR: 10,
      DEX: 10,
      CON: 10,
      INT: 10,
      WIS: 10,
      CHA: 10,
    },
    final_ability_score: {
      STR: 10,
      DEX: 10,
      CON: 10,
      INT: 10,
      WIS: 10,
      CHA: 10,
    },
    levels: []
  }
})
@Injectable()
export class CharacterBuilderState {

  @Selector()
  static abilityScores(state: CharacterBuilderStateModel): AbilityScore {
    return state.ability_score
  }

  @Selector()
  static finalAbilityScores(state: CharacterBuilderStateModel): AbilityScore {
    return state.final_ability_score
  }

  @Selector()
  static race(state: CharacterBuilderStateModel) {
    return state.race
  }

  @Selector()
  static levels(state: CharacterBuilderStateModel): Array<Level> {
    return state.levels
  }

  constructor() {}

  @Action(SetAbilityScore)
  setAbilityScore(
    { getState, setState, dispatch }: StateContext<CharacterBuilderStateModel>,
    { ability, score }: SetAbilityScore
  ) {
    const state = produce(getState(), draft => {
      if (!score || score < 0) {
        score = 0
      }
      draft.ability_score[ability] = score
    })
    setState(state)
    return dispatch(new CalculateFinalAbilityScore())
  }

  @Action(SelectRace)
  selectRace(
    { getState, setState, dispatch }: StateContext<CharacterBuilderStateModel>,
    { race }: SelectRace
  ) {
    const state = produce(getState(), draft => {
      draft.race = race
    })

    setState(state)
    return dispatch(new CalculateFinalAbilityScore())
  }

  @Action(AddRaceCustomAbility)
  addRaceCustomAbility(
    { getState, setState, dispatch }: StateContext<CharacterBuilderStateModel>,
    { ability }: AddRaceCustomAbility
  ) {
    const draftState = produce(getState(), draft => {
      if (!draft.race.ability[1]) {
        draft.race.ability[1] = {}
      }
      draft.race.ability[1][ability] = 1
    })

    setState(draftState)
    return dispatch(new CalculateFinalAbilityScore())
  }

  @Action(CalculateFinalAbilityScore)
  calculateFinalAbilityScore(
    { getState, setState }: StateContext<CharacterBuilderStateModel>,
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      for (const abilityKey of (Object.keys(state.ability_score))) {
        draft.final_ability_score[abilityKey] = +state.ability_score[abilityKey]
      }
      if (state.race && state.race.ability) {
        for (const raceAbilityKey of Object.keys(state.race.ability[0])) {
          draft.final_ability_score[raceAbilityKey.toUpperCase()] += state.race.ability[0][raceAbilityKey]
        }
        if (state.race.ability[1]) {
          for (const raceAbilityKey of Object.keys(state.race.ability[1])) {
            draft.final_ability_score[raceAbilityKey.toUpperCase()] += state.race.ability[1][raceAbilityKey]
          }
        }
      }
    })
    return setState(draftState)
  }

  @Action(SelectCharacterClass)
  selectCharacterClass(
    { getState, setState }: StateContext<CharacterBuilderStateModel>,
    { rootCharacterClass, characterClass, subclass, levels, totalLevels }: SelectCharacterClass
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      for (let i = 1; i <= levels; i++) {
        const level: Level = {
          // root: rootCharacterClass,
          classLevel: totalLevels + i, // stores the class level
          class: characterClass,
          subclass: (totalLevels >= 3 || totalLevels + i >= 3) ? subclass : undefined
        }
        draft.levels.push(level)
      }
    })
    return setState(draftState)
  }

  @Action(ResetLevels)
  resetLevels(
    { getState, setState }: StateContext<CharacterBuilderStateModel>
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      draft.levels = []
    })
    return setState(draftState)
  }
}
