<mat-card class="card">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title>Tiers & Attunement</mat-card-title>
    <mat-card-content fxLayoutGap="10px">
      <h4><b>Tier 1: Levels 3 and 4 -</b> Local Heroes</h4>
      <div>1 Attunement Slot</div>
      <div>+1 Weapons and Armors Max</div>
      <div>Anything that summons a minion requires attunement.</div>
      <div>All Wands require attunement.</div>
      <h4><b>Tier 2: Levels 5-10 </b>- Heroes of the Realm</h4>
      <div>2 Attunement Slots</div>
      <div>+2 Weapons and Armors Max</div>
      <div>Anything that summons a minion requires attunement.</div>
      <div>All Wands require attunement.</div>
      <h4><b>Tier 3: Levels 11-16</b> - Masters of the Realm</h4>
      <div>3 Attunement Slots</div>
      <h4><b>Tier 4: Levels 17-20</b> - Masters of the World</h4>
      <div>3 Attunement Slots</div>
    </mat-card-content>
  </div>
</mat-card>
