import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donated-material',
  templateUrl: './donated-material.component.html',
  styleUrls: ['./donated-material.component.scss']
})
export class DonatedMaterialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
