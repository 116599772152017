import { AngularFireDatabase } from '@angular/fire/database'
import { Component, OnInit } from '@angular/core'
import { DmState } from '../states/dm/dm.state'
import { FormControl, Validators } from '@angular/forms'
import { Login, Logout, AddFAQ } from '../states/dm/dm.actions'
import { Observable } from 'rxjs'
import { Store, Select } from '@ngxs/store'

@Component({
  selector: 'app-dm-landing',
  templateUrl: './dm-landing.component.html',
  styleUrls: ['./dm-landing.component.scss']
})
export class DmLandingComponent implements OnInit {
  @Select(DmState.isLoggedIn)
  isLoggedIn$: Observable<boolean>
  isLoggedIn = false

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ])

  passwordFormControl = new FormControl('', [
    Validators.required,
  ])

  questionFormControl = new FormControl('', [
    Validators.required,
  ])

  answerFormControl = new FormControl('', [
    Validators.required,
  ])

  password: string

  constructor(
    private store: Store,
    private firestore: AngularFireDatabase
  ) {}

  ngOnInit(): void {
    this.isLoggedIn$.subscribe(status => {
      this.isLoggedIn = status
    })
  }

  onLogin() {
    if (this.emailFormControl.valid && this.passwordFormControl.valid) {
      this.store.dispatch(new Login(this.emailFormControl.value.trim(), this.passwordFormControl.value.trim()))
    }
  }

  onLogout() {
    this.store.dispatch(new Logout())
  }

  onAddToFAQ() {
    if (this.questionFormControl.valid && this.answerFormControl.valid) {
      this.store.dispatch(new AddFAQ(this.questionFormControl.value, this.answerFormControl.value)).subscribe(() => {
        this.questionFormControl.reset()
        this.answerFormControl.reset()
      })
    }
  }
}
