export class SetAbilityScore {
  static readonly type = '[CB] Set Ability Score'
   constructor(public ability: string, public score: number) {}
}

export class SelectRace {
  static readonly type = '[CB] Select Race'
  constructor(public race: any) {}
}

export class AddRaceCustomAbility {
  static readonly type = '[CB] Add Race Custom Ability'
  constructor(public ability: string) {}
}

export class CalculateFinalAbilityScore {
  static readonly type = '[CB] Calculate Final Ability Score'
}

export class SelectCharacterClass {
  static readonly type = '[CB] Select Class'
  constructor(
    public rootCharacterClass: any,
    public characterClass: any,
    public subclass?: any,
    public levels?: number,
    public totalLevels?: any
  ) {}
}

export class ResetLevels {
  static readonly type = '[CB] Reset Levels'
}
