import { Component, OnInit } from '@angular/core'
import { Filter } from '../states/homepage/homepage.model'
import { HomepageState } from 'src/app/states/homepage/homepage.state'
import { Observable } from 'rxjs'
import { SetFilterStatus } from '../states/homepage/homepage.actions'
import { StateReset } from 'ngxs-reset-plugin'
import { Store, Select } from '@ngxs/store'

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  @Select(HomepageState.filters)
  filters$: Observable<Array<Filter>>
  filters: Array<Filter>

  constructor(
    private store: Store
  ) {
    this.filters$.subscribe(filters => {
      this.filters = filters
    })
  }

  ngOnInit(): void {
  }

  toggleSection(index: number, active: boolean) {
    this.store.dispatch(new SetFilterStatus(index, !active))
  }
}
