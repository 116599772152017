import { Component, OnInit } from '@angular/core'
import { Store, Select, Actions } from '@ngxs/store'
import { Observable, Subject } from 'rxjs'
import { AbilityScore } from 'src/app/states/character-builder/character-builder.model'
import { CharacterBuilderState } from 'src/app/states/character-builder/charter-builder.state'
import { SetAbilityScore } from 'src/app/states/character-builder/character-builder.actions'

@Component({
  selector: 'app-ability-score',
  templateUrl: './ability-score.component.html',
  styleUrls: ['./ability-score.component.scss']
})
export class AbilityScoreComponent implements OnInit {
  @Select(CharacterBuilderState.abilityScores)
  abilityScores$: Observable<AbilityScore>

  @Select(CharacterBuilderState.finalAbilityScores)
  finalAbilityScores$: Observable<AbilityScore>

  finalAbilityScores: AbilityScore

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.finalAbilityScores$.subscribe(scores => {
      this.finalAbilityScores = scores
    })
  }

  onInputChange(ability: string, event: any) {
    const score = event.target.value
    this.store.dispatch(new SetAbilityScore(ability, score))
  }

}
