<div class="main" fxLayout="column">
  <mat-toolbar color="primary" fxLayout="row" fxLayoutGap="5px">
    <button *ngIf="url === '/'" mat-stroked-button class="menu-button" (click)="onToggleNav()">
      <mat-icon>menu</mat-icon>
    </button>
    <a href="/" *ngIf="url !== '/'" mat-stroked-button class="menu-button">
      <mat-icon>home</mat-icon>
    </a>
    <div>Ruthgar's Rebels</div>
    <div fxFlex></div>
    <a mat-stroked-button href="/dm">DM Portal</a>
  </mat-toolbar>
  <mat-drawer-container class="container" autosize>
    <mat-drawer #drawer class="sidenav" mode="side" [opened]="isNavOpen">
      <mat-list class="nav-list" fxLayout="column">
        <ng-container *ngIf="url === '/'">
          <mat-list-item *ngFor="let filter of filters$ | async; let i = index" [ngClass]="{ 'active': filter.active }">
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" fxFlexFill (click)="toggleSection(i, filter.active)">
              <div>{{ filter.name }}</div>
              <div fxFlex></div>
              <mat-checkbox [checked]="filter.active"></mat-checkbox>
            </div>
          </mat-list-item>
          <a href="https://5etools.ruthgars.com" target="_blank" fxFlex>
            <mat-list-item>
                <div fxFlex>5eTools</div>
                <div fxFlex></div>
                <mat-icon>open_in_new</mat-icon>
            </mat-list-item>
          </a>
          <a href="https://docs.google.com/spreadsheets/d/1qOyjS5lyQultqXfI2LelEeIW9BqE87R_KLIbKn5OX7I/edit?usp=sharing" target="_blank">
            <mat-list-item>
              <div fxFlex>Ruthgar's Shop</div>
              <div fxFlex></div>
              <mat-icon>open_in_new</mat-icon>
            </mat-list-item>
          </a>
          <a href="https://docs.google.com/document/d/1hEHfN3nNn9iuscjYkCT4llBYVY-_IpOwbeEuzsaJXP8/edit?usp=sharing" target="_blank">
            <mat-list-item>
              <div fxFlex style="white-space: nowrap;">Turnip Farming 101</div>
              <div fxFlex></div>
              <mat-icon>open_in_new</mat-icon>
            </mat-list-item>
          </a>
          <a href="/lootgen" target="_blank">
            <mat-list-item>
              <div fxFlex style="white-space: nowrap;">Loot Generator</div>
              <div fxFlex></div>
              <mat-icon>open_in_new</mat-icon>
            </mat-list-item>
          </a>
        </ng-container>
        <div fxFlex></div>
        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
          Last Updated ({{ version }})
        </div>
      </mat-list>
    </mat-drawer>
    <div class="main-panel">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>