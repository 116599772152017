import { Component } from '@angular/core'
import { HomepageState, VERSION } from './states/homepage/homepage.state'
import { Observable } from 'rxjs'
import { Store, Select } from '@ngxs/store'
import * as moment from 'moment'
import { Filter } from './states/homepage/homepage.model'
import { SetFilterStatus, ToggleNav } from './states/homepage/homepage.actions'
import { StateReset } from 'ngxs-reset-plugin'
import { Router, NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  @Select(HomepageState.version)
  version$: Observable<number>
  version: string

  @Select(HomepageState.isNavOpen)
  isNavOpen$: Observable<boolean>
  isNavOpen = false

  @Select(HomepageState.filters)
  filters$: Observable<Array<Filter>>
  filters: Array<Filter>

  url: string

  constructor(
    private store: Store,
    private router: Router,
  ) {
    this.version$.subscribe(v => {
      if (!v || v < VERSION) {
        this.store.dispatch(
          new StateReset(HomepageState)
        )
      }
      this.version = moment(v, 'YYYYMMDD').format('YYYY-MM-DD')
    })

    this.isNavOpen$.subscribe(isNavOpen => {
      this.isNavOpen = isNavOpen
    })

    this.router.events.subscribe(event => {
      if ( event instanceof NavigationEnd ) {
        this.url = event.url
      }
    })
  }
  onToggleNav() {
    console.log('onToggleNav called')
    this.store.dispatch(new ToggleNav())
  }

  toggleSection(index: any, active: boolean) {
    this.store.dispatch(new SetFilterStatus(index, !active))
  }
}
