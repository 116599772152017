import { Component, OnInit, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { CharacterBuilderState } from 'src/app/states/character-builder/charter-builder.state';
import { Observable } from 'rxjs';
import { Level } from 'src/app/states/character-builder/character-builder.model';
import { VALID_CLASSES } from 'src/app/utils/valid-classes';
import * as _ from 'lodash';
import { VALID_SOURCES } from 'src/app/utils/valid-sources';

@Component({
  selector: 'app-level-card',
  templateUrl: './level-card.component.html',
  styleUrls: ['./level-card.component.scss']
})
export class LevelCardComponent implements OnInit {
  @Input() levelIndex: number
  @Input() level: Level

  @Select(CharacterBuilderState.levels)
  levels$: Observable<Array<Level>>
  availableClasses: any
  displayFeatures: Array<any>
  missingSubclass: boolean
  isSpellCaster: boolean
  spellsForLevel: number

  constructor() { }

  ngOnInit(): void {
    this.availableClasses = VALID_CLASSES
    this.displayFeatures = []
    this.missingSubclass = false
    this.isSpellCaster = false
    this.init()
  }

  init() {
    const rootClass = _.find(this.availableClasses, klass => {
      if (klass.name === this.level.class.name) {
        return true
      }
    })
    if (this.level.classLevel === 3) {
      console.log('rootClass', rootClass)
      console.log('level', this.level)
    }

    if (
      (this.level.class.spellcastingAbility && this.level.class.casterProgression)
      || (this.level.subclass && this.level.subclass.spellcastingAbility)
    ) {
      // let slotLevel = 0
      // let pactLevel = 0
      this.isSpellCaster = true
      // switch (this.level.class.spellcastingAbility) {
      //   case 'third':
      //     slotLevel += Math.floor(this.level.classLevel / 3)
      //     break
      //   case '1/2':
      //     slotLevel += Math.floor(this.level.classLevel / 2)
      //     break
      //   case 'full':
      //     slotLevel += this.level.classLevel
      //     break
      //   case 'artificer':
      //     slotLevel += Math.ceil(this.level.classLevel / 2)
      //     break
      //   case 'pact':
      //     pactLevel += this.level.classLevel
      //     break
      // }
    }

    // Generic Class
    this.initClass(rootClass)
    if (this.level.classLevel >= 3) {
      if (this.level.subclass) {
        this.initSubClass(rootClass)
      } else {
        this.missingSubclass = true
      }
    }
  }

  initClass(rootClass: any) {
    for (const classFeature of this.level.class.classFeatures) {
      let featureText = classFeature
      if (typeof(classFeature) === 'object') {
        featureText = classFeature.classFeature
      }
      const [ featureName, featureClass, splitSource, featureLevel, variant ] = featureText.split('|')
      if (Number(featureLevel) > this.level.classLevel) {
        // Features are ordered already by level
        break
      }
      let featureSource = splitSource
      if (!featureSource) {
        featureSource = 'PHB'
      }
      if (Number(featureLevel) === this.level.classLevel && VALID_SOURCES.indexOf(featureSource) !== -1) {
        const fullFeature = _.find(rootClass.classFeature, rootFeature => {
          if (rootFeature.name === featureName && rootFeature.source === featureSource) {
            return true
          }
        })
        if (fullFeature) {
          this.displayFeatures.push(fullFeature)
        }
      }
    }
  }

  initSubClass(rootClass: any) {
    for (const classFeature of this.level.subclass.subclassFeatures) {
      let featureText = classFeature
      if (typeof(classFeature) === 'object') {
        featureText = classFeature.classFeature
      }
      const [ featureName, featureClass, featureSource, featureSubclass, featureSubclassSource, featureLevel ] = featureText.split('|')
      if (Number(featureLevel) > this.level.classLevel) {
        // Features are ordered already by level
        break
      }
      if (Number(featureLevel) === this.level.classLevel && VALID_SOURCES.indexOf(featureSource) !== -1) {
        const fullFeature = _.find(rootClass.subclassFeature, rootFeature => {
          if (rootFeature.name === featureName) {
            return true
          }
        })
        if (fullFeature) {
          this.displayFeatures.push(fullFeature)
        }
      }
    }
  }
}
