<div fxFlex fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutGap="10px">
    <app-ability-score></app-ability-score>
    <div fxLayout="column" fxLayoutGap="10px">
      <app-race-picker></app-race-picker>
      <app-class-picker></app-class-picker>
    </div>
    <app-character-levels></app-character-levels>
    <app-spell-picker></app-spell-picker>
  </div>
  <div fxLayout="column" fxLayoutGap="10px">
    <app-level-config></app-level-config>
  </div>
</div>