<mat-card class="ability-score">
  <mat-card-title>Ability Score</mat-card-title>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="5px" *ngIf="abilityScores$ | async as abilityScore">
      <div fxLayout="row">
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="abilityScore.STR" placeholder="STR" (change)="onInputChange('STR', $event)">
        </mat-form-field>
        <div fxFlex></div>
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="finalAbilityScores?.STR" placeholder="Final STR">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="abilityScore.DEX" placeholder="DEX" (change)="onInputChange('DEX', $event)">
        </mat-form-field>
        <div fxFlex></div>
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="finalAbilityScores?.DEX" placeholder="Final DEX">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="abilityScore.CON" placeholder="CON" (change)="onInputChange('CON', $event)">
        </mat-form-field>
        <div fxFlex></div>
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="finalAbilityScores?.CON" placeholder="Final CON">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="abilityScore.INT" placeholder="INT" (change)="onInputChange('INT', $event)">
        </mat-form-field>
        <div fxFlex></div>
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="finalAbilityScores?.INT" placeholder="Final INT">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="abilityScore.WIS" placeholder="WIS" (change)="onInputChange('WIS', $event)">
        </mat-form-field>
        <div fxFlex></div>
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="finalAbilityScores?.WIS" placeholder="Final WIS">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="abilityScore.CHA" placeholder="CHA" (change)="onInputChange('CHA', $event)">
        </mat-form-field>
        <div fxFlex></div>
        <mat-form-field class="as-input">
          <input matInput type="number" [ngModel]="finalAbilityScores?.CHA" placeholder="Final CHA">
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>