<div *ngIf="filters" fxLayout="column" fxLayoutGap="20px" class="homepage">
  <div fxLayout="row wrap" fxLayoutGap="10px grid">
    <div fxLayout="column" fxLayoutGap="10px">
      <app-server-rules *ngIf="filters | sectionVisible: 'server-rules'"></app-server-rules>
      <app-rules *ngIf="filters | sectionVisible: 'character-rules'"></app-rules>
      <app-hp-calculator *ngIf="filters | sectionVisible: 'hp-calculator'"></app-hp-calculator>
      <app-casting-service-calculator *ngIf="filters | sectionVisible: 'casting-services-calculator'"></app-casting-service-calculator>
    </div>
    <div *ngIf="filters | sectionVisible: 'allowed-material'" fxLayout="column" fxLayout.md="row" fxLayoutGap="10px">
      <app-allowed-material></app-allowed-material>
      <app-donated-material></app-donated-material>
    </div>
    <app-downtime *ngIf="filters | sectionVisible: 'downtime'"></app-downtime>
  </div>
</div>