import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-loot-generator',
  templateUrl: './loot-generator.component.html',
  styleUrls: ['./loot-generator.component.scss']
})

export class LootGeneratorComponent implements OnInit {

  SheetHeaders = SHEET_HEADERS
  allItems = []

  tiers: ITier[] = [{
    name: 'Tier 1',
    minGP: 200,
    maxGP: 500,
    minItem: 0,
    maxItem: 120
  }, {
    name: 'Low Tier 2',
    minGP: 4000,
    maxGP: 6000,
    minItem: 120,
    maxItem: 800
  }, {
    name: 'High Tier 2',
    minGP: 10000,
    maxGP: 25000,
    minItem: 800,
    maxItem: 2500
  }, {
    name: 'Low Tier 3',
    minGP: 35000,
    maxGP: 45000,
    minItem: 2500,
    maxItem: 4000
  }, {
    name: 'High Tier 3',
    minGP: 50000,
    maxGP: 70000,
    minItem: 4000,
    maxItem: 8000
  }, {
    name: 'Tier 4',
    minGP: 150000,
    maxGP: 250000,
    minItem: 8000,
    maxItem: 20000
  }]
  selectedTier: ITier = this.tiers[0]
  playerCount = 6

  generatedLoot: IGeneratedLoot[] = []

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchItems()
  }

  async fetchItems() {
    // I hate Google and their APIs...
    // The apiKey is public. I don't care. I hate Google and I dont want to run a backend service
    // just to read some damned JSON data that's public. Restricted to Google sheets and can only
    // come from https://www.ruthgars.com/lootgen
    const apiKey = 'AIzaSyB5rDAaWzs7QMJcFhvy9Re_s9DKoAttpYg'
    const googleSheetsId = '1qOyjS5lyQultqXfI2LelEeIW9BqE87R_KLIbKn5OX7I'
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${googleSheetsId}/values/Magical?alt=json&key=${apiKey}`
    const sheet = await this.http.get<any>(url).toPromise()
    // Drop Header
    const magicItems = sheet.values.slice(1, -1)
    this.allItems = magicItems.sort((a, b) => {
      const aPrice = this.normalizePrice(a[SHEET_HEADERS.PRICE])
      const bPrice = this.normalizePrice(b[SHEET_HEADERS.PRICE])
      return aPrice - bPrice
    })
  }


  generateGoldGP(tier: ITier): number {
    return Math.floor(Math.random() * (tier.maxGP - tier.minGP + 1)) + tier.minGP
  }

  generateItems(tier: ITier, lootGP: number) {
    const maxItemGPTotal = tier.maxGP - lootGP
    let itemsTotalGP = 0
    const items = []
    // Generate items as long as the total itemGP is less than the maxItemGPTotal

    // Grab a list of items for this tier (5)
    const filteredItemsList = this.allItems.filter(i => {
      return this.normalizePrice(i[SHEET_HEADERS.PRICE]) >= tier.minItem && this.normalizePrice(i[SHEET_HEADERS.PRICE]) <= tier.maxItem
    })

    while (itemsTotalGP < maxItemGPTotal) {
      const randomItemIndex = Math.round(Math.random() * filteredItemsList.length)
      items.push(filteredItemsList[randomItemIndex])
      itemsTotalGP += this.normalizePrice(filteredItemsList[randomItemIndex][SHEET_HEADERS.PRICE])
    }

    return { itemsTotalGP, items }
  }

  normalizePrice(price) {
    return parseFloat(price.replace(',', ''));
  }

  onGenerate() {
    const tier = this.selectedTier.name
    const gp = this.generateGoldGP(this.selectedTier)
    const { itemsTotalGP, items } = this.generateItems(this.selectedTier, gp)
    this.generatedLoot.unshift({ tier, gp, itemsTotalGP, items})
  }

  onSellItem(generatedLootIndex, lootItemIndex, lootItemPrice) {
    this.generatedLoot[generatedLootIndex].items.splice(lootItemIndex, 1)
    this.generatedLoot[generatedLootIndex].gp += parseFloat(lootItemPrice)
    this.generatedLoot[generatedLootIndex].itemsTotalGP -= parseFloat(lootItemPrice)
  }

  onPlayerCountChanged(value: number) {
    if (this.playerCount < 2) {
      this.playerCount = 2
    } else if (this.playerCount > 10) {
      this.playerCount = 9
    } else {
      this.playerCount = value
    }
  }
}

export enum SHEET_HEADERS {
  NAME = 0,
  PRICE = 1,
  RARITY = 2,
  SOURCE = 3,
  PAGE = 4,
  TYPE = 5,
  ATTUNEMENT = 6,
  LINKS = 7
}

export interface ITier {
  name: string
  minGP: number
  maxGP: number
  minItem: number
  maxItem: number
}

export interface IGeneratedLoot {
  tier: string;
  gp: number
  itemsTotalGP: number;
  items: number[]
}
