import { Injectable } from '@angular/core'
import { HomepageStateModel, Filter } from './homepage.model'
import { SetFilterStatus, ToggleNav } from './homepage.actions'
import { produce } from 'immer'
import { State, Selector, Action, StateContext } from '@ngxs/store'

export const VERSION = 20220109

@State({
  name: 'homepage',
  defaults: {
    version: VERSION,
    isNavOpen: false,
    filters: [{
      id: 'allowed-material',
      name: 'Allowed Material',
      active: true
    }, {
      id: 'character-rules',
      name: 'Rules',
      active: true
    }, {
      id: 'downtime',
      name: 'Downtime',
      active: true
    }, {
      id: 'hp-calculator',
      name: 'HP Calculator',
      active: false
    }, {
      id: 'server-rules',
      name: 'Server Rules',
      active: true
    }, {
      id: 'casting-services-calculator',
      name: 'Spell Services Caclulator',
      active: false
    }]
  }
})

@Injectable()
export class HomepageState {

  @Selector()
  static version(state: HomepageStateModel) {
    return state.version
  }

  @Selector()
  static filters(state: HomepageStateModel) {
    return state.filters
  }

  @Selector()
  static isNavOpen(state: HomepageStateModel) {
    return state.isNavOpen
  }

  @Action(ToggleNav)
  toggleNav(
    { getState, setState }: StateContext<HomepageStateModel>
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      draft.isNavOpen = !state.isNavOpen
    })
    setState(draftState)
  }

  @Action(SetFilterStatus)
  setFilterStatus(
    { getState, setState }: StateContext<HomepageStateModel>,
    { index, active }: SetFilterStatus
  ) {
    const draftState = produce(getState(), draft => {
      draft.filters[index].active = active
    })
    setState(draftState)
  }
}
