<mat-dialog-content>
  <mat-form-field fxFill>
    <input matInput type="text" [formControl]="questionFormControl" placeholder="Question">
  </mat-form-field>
  <mat-form-field fxFill>
    <textarea  matInput type="text" [formControl]="answerFormControl" placeholder="A long answer here"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row">
  <button mat-raised-button color="secondary" (click)="onCancel()">Close</button>
  <div fxFlex></div>
  <button mat-raised-button color="warn" (click)="updateFAQ()">Update FAQ</button>
</mat-dialog-actions>
