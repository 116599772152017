<mat-card *ngIf="faqs" class="card">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title>FAQ</mat-card-title>
    <mat-card-content fxLayout="column" fxLayoutGap="10px">
      <div *ngFor="let faq of faqs; let index = i" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div *ngIf="(isLoggedIn$ | async)">
            <button mat-raised-button class="button" (click)="onEdit(faq)">
              Edit
            </button>
          </div>
          <div class="question">{{ faq.data.question }}</div>
          <div fxFlex></div>
        </div>
        <div class="answer">{{ faq.data.answer }}</div>
      </div>
    </mat-card-content>
  </div>
</mat-card>