<mat-card *ngIf="this.messages">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title>
      <div>Character Search - DEPRECATED USE BOT !char Character Name</div>
      <div fxFlex></div>
      <div>
        <button *ngIf="(username || discordUsername) && (characterName || characterNameAlt)" mat-raised-button color="primary" (click)="searchCharacter()">
          Search
        </button>
      </div>
    </mat-card-title>
    <mat-card-content fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field fxFlex>
          <input matInput placeholder="@Username" [(ngModel)]="username" trim="blur">
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="DiscordUsername#2302" [(ngModel)]="discordUsername" trim="blur">
          <mat-hint>Optional if different from @Username</mat-hint>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field fxFlex>
          <input matInput placeholder="Character Name" [(ngModel)]="characterName" trim="blur">
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="Alt Char Name|Different Name" [(ngModel)]="characterNameAlt" trim="blur">
          <mat-hint>Optional if lazy DM or misspell (Use |)</mat-hint>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-accordion *ngIf="totalXP >= 0">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ characterName }}, House of {{ username }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutGap="10px">
          <div>Downtime Days: {{ downtimeDays }}</div>
          <div fxLayout="row" fxLayoutGap="10px">
            <div>Total XP: {{ totalXP }}</div>
            <div>Total GP: {{ totalGP }}</div>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <div>Total Games: {{ totalGames }}</div>
            <div>Weeks Played: {{ weeksPlayed }}</div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</mat-card>