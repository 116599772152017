import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireDatabase } from '@angular/fire/database'
import { auth } from 'firebase'
import { DmStateModel } from './dm.model'
import { Injectable } from '@angular/core'
import { Login, SetLoggedIn, Logout, AddFAQ, EditFAQ } from './dm.actions'
import { produce } from 'immer'
import { State, Selector, Action, StateContext } from '@ngxs/store'
import * as moment from 'moment'

@State({
  name: 'dm',
  defaults: {
    isLoggedIn: false
  }
})
@Injectable()
export class DmState {
  private readonly authProvider: auth.EmailAuthProvider

  @Selector()
  static isLoggedIn(state: DmStateModel): boolean {
    return state.isLoggedIn
  }

  constructor(
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFireDatabase
  ) {}

  async ngxsOnInit(
    { getState, setState, dispatch }: StateContext<DmStateModel>
  ) {
    this.angularFireAuth.onAuthStateChanged(user => {
      dispatch(new SetLoggedIn(user))
    })
  }

  @Action(Login)
  login(
    { getState, setState }: StateContext<DmStateModel>,
    { email, password }: Login
  ) {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password)
  }

  @Action(SetLoggedIn)
  setLoggedIn(
    { getState, setState }: StateContext<DmStateModel>,
    { user }: SetLoggedIn
  ) {
    const draftState = produce(getState(), draft => {
      draft.user = user
      draft.isLoggedIn = !!user
    })
    setState(draftState)
  }

  @Action(Logout)
  logout(
    { getState, setState }: StateContext<DmStateModel>
  ) {
    return this.angularFireAuth.signOut()
  }

  @Action(AddFAQ)
  addFAQ(
    { getState, setState }: StateContext<DmStateModel>,
    { question, answer }: AddFAQ
  ) {
    const state = getState()
    const faqRef = this.firestore.database.ref('faq')
    const newPostRef = faqRef.push()
    const created = moment().format()
    newPostRef.set({
      question,
      answer,
      created,
      updated: created,
      log: [{
        action: 'created',
        user: state.user.uid
      }]
    })
  }

  @Action(EditFAQ)
  editFAQ(
    { getState }: StateContext<DmStateModel>,
    { faq, question, answer }: EditFAQ
  ) {
    const state = getState()
    const faqRef = this.firestore.database.ref(`faq/${faq.key}`)
    const updated = moment().format()
    const newFaq = faq.data
    newFaq.question = question
    newFaq.answer = answer
    newFaq.updated = updated
    newFaq.log.push({
      action: 'updated',
      user: state.user.uid,
      date: updated,
      original: {
        question: faq.data.question,
        answer: faq.data.answer,
      }
    })
    faqRef.set(newFaq)
  }
}
