<mat-card class="card">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title>Ruthgar's HP Calculator</mat-card-title>
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="conMod" placeholder="CON Mod">
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-around center">
      <mat-form-field>
        <input type="number" [(ngModel)]="activeHD"
               placeholder="Class Hit Dice"
               aria-label="Class Hit Dice Number"
               matInput
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let c of CLASSES" [value]="c.hd">
            {{c.name}} - d{{ c.hd }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" [(ngModel)]="activeLevels" placeholder="Levels">
      </mat-form-field>
      <button mat-raised-button color="accent" (click)="addLevels(activeHD, activeLevels)">Add Class</button>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-checkbox [(ngModel)]="hasToughFeat" (ngModelChange)="calculateHP()">Feat: Tough</mat-checkbox>
      <mat-checkbox [(ngModel)]="isHillDwarf" (ngModelChange)="calculateHP()">Hill Dwarf</mat-checkbox>
      <mat-checkbox *ngIf="activeHD === 6" [(ngModel)]="hasDraconic" (ngModelChange)="calculateHP()">Draconic Resilience</mat-checkbox>
      <mat-checkbox [(ngModel)]="isLegacy" (ngModelChange)="calculateHP()">Legacy</mat-checkbox>
    </div>
    <div>
      <div fxLayout="row" fxLayoutGap="10px">
        <button mat-raised-button color="primary" (click)="calculateHP()">Calculate Total HP</button>
        <button mat-raised-button color="warn" (click)="reset()">Reset</button>
      </div>
      <div *ngIf="error">{{ error }}</div>
    </div>
    <div *ngIf="totalHP">
      Total HP: {{ totalHP }}
    </div>
    <mat-accordion *ngIf="characterClasses.length > 0">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Formula
          </mat-panel-title>
          <mat-panel-description>
            (Don't trust me?)
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="column" *ngFor="let cc of characterClasses">
          Level: {{ cc.level }}: d{{ cc.hd }} ({{ cc.formula }})
        </div>
        <ng-container *ngIf="totalHP >= 0">
          <div *ngIf="hasToughFeat">
            Tough: {{ 2 * characterClasses.length }}
          </div>
          <div *ngIf="isHillDwarf">
            Hill Dwarf: {{ characterClasses.length }}
          </div>
          <div *ngIf="isLegacy">
           Legacy: {{ characterClasses.length - 1 }}
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</mat-card>
