import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database'
import { Select, Store } from '@ngxs/store'
import { DmState } from '../states/dm/dm.state'
import { Observable } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { FaqDialogComponent } from '../faq-dialog/faq-dialog.component';
import { EditFAQ } from '../states/dm/dm.actions';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqListRef: any
  faqs: any

  @Select(DmState.isLoggedIn)
  isLoggedIn$: Observable<boolean>

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private firestore: AngularFireDatabase
  ) {
    this.faqListRef = this.firestore.database.ref('faq')
    this.faqListRef.on('value', snapshot => {
      this.setSnapshotData(snapshot)
    })
  }

  ngOnInit(): void {
    this.faqListRef.once('value', snapshot => {
      this.setSnapshotData(snapshot)
    })
  }

  setSnapshotData(snapshot) {
    this.faqs = []
    snapshot.forEach(childSnapshot => {
      const key = childSnapshot.key
      const data = childSnapshot.val()

      this.faqs.push({
        key,
        data
      })
    })
  }

  onEdit(faq: any) {
    const dialogRef = this.dialog.open(FaqDialogComponent, {
      data: faq.data
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new EditFAQ(faq, result.question, result.answer))
      }
    })
  }
}
