<mat-card class="card">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title>Server Rules</mat-card-title>
    <mat-card-content>
      <ol fxLayout="column" fxLayoutGap="5px">
        <li>Your Roll20 display name and your Discord display name should match. <span class="underline">Update <code>#roster</code> with  Roll20 username, Roll20 display name, and Discord <code>@username</code></span>.</li>
        <li><a href="https://docs.google.com/document/d/1hEHfN3nNn9iuscjYkCT4llBYVY-_IpOwbeEuzsaJXP8/edit?usp=sharing">Turnip Farming 101</a> document is a basic how-to guide with a step by step on getting started on Ruthgar's.</li>
        <li>Microphone, Discord, and a computer is <b class="accent">required</b> to play on Ruthgar's Roll20. Missing any of these means you cannot play in Ruthgar's games.</li>
        <li><b>Be respectful.</b> Discrimination or hate speech based on race, sex, gender, age, or sexuality, sexual harassment, malicious behavior, spam, adveritising, and bullying is strictly prohibited and will result in an immediate ban.</li>
        <li><b>Learn your class.</b> Take the time to read what your character can and cannot do. It is not the DM’s responsibility to play your character. You want to be able to contribute to your party, make sure you know what you can be doing. We are new player friendly, but at least make sure you read everything and have a general idea.</li>
        <li>DM rule is final, no arguments. Any issues may be brought up in <code>#5e-questions</code> outside of game.</li>
        <li>During a session, do not interrupt other players and do not speak out of your turn during combat.</li>
        <li>No PVP or stealing from other players.</li>
        <li>If you have a complaint about a DM or a user, you are welcome to PM any online DM.</li>
      </ol>
    </mat-card-content>
  </div>
</mat-card>