import { CharacterBuilderState } from 'src/app/states/character-builder/charter-builder.state'
import { Component, OnInit } from '@angular/core';
import { Level } from 'src/app/states/character-builder/character-builder.model'
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { VALID_CLASSES } from 'src/app/utils/valid-classes'
import { VALID_SOURCES } from 'src/app/utils/valid-sources'
import * as _ from 'lodash'

@Component({
  selector: 'app-level-config',
  templateUrl: './level-config.component.html',
  styleUrls: ['./level-config.component.scss']
})
export class LevelConfigComponent implements OnInit {
  @Select(CharacterBuilderState.levels)
  levels$: Observable<Array<Level>>
  startingLevel: Level

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.levels$.subscribe(levels => {
      for (let i = 0; i < levels.length; i++) {
        const level = levels[i]
        if (i === 0) {
          this.startingLevel = level
        }
        // this.levelDisplayFn(level)
      }
    })
  }
}
