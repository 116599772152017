import { Component, OnInit } from '@angular/core'

interface CharacterClass {
  hd: number
  level: number
  isDraconic?: boolean
  formula?: string
}

@Component({
  selector: 'app-hp-calculator',
  templateUrl: './hp-calculator.component.html',
  styleUrls: ['./hp-calculator.component.scss']
})
export class HpCalculatorComponent implements OnInit {

  error: string
  totalHP: number
  activeHD: number
  activeLevels: number
  conMod: number

  hasToughFeat: boolean
  isHillDwarf: boolean
  hasDraconic: boolean
  isLegacy: boolean

  characterClasses: Array<CharacterClass>
  CLASSES = [
    { name: 'Artificer', hd: 8 },
    { name: 'Barbarian', hd: 12 },
    { name: 'Bard', hd: 8 },
    { name: 'Blood Hunter', hd: 10 },
    { name: 'Cleric', hd: 8 },
    { name: 'Druid', hd: 8 },
    { name: 'Fighter', hd: 10 },
    { name: 'Monk', hd: 8 },
    { name: 'Paladin', hd: 10 },
    { name: 'Ranger', hd: 10 },
    { name: 'Rogue', hd: 8 },
    { name: 'Sorcerer', hd: 6 },
    { name: 'Warlock', hd: 8 },
    { name: 'Witch', hd: 8 },
    { name: 'Wizard', hd: 6 }
  ]

  reset() {
    this.characterClasses = new Array()
    this.conMod = 0
    this.activeHD = undefined
    this.activeLevels = undefined
    this.totalHP = undefined
    this.hasToughFeat = false
    this.isHillDwarf = false
    this.hasDraconic = false
    this.isLegacy = false
  }

  ngOnInit() {
    this.reset()
  }

  addLevels(hd: number, levels: number) {
    if (isNaN(hd) || isNaN(levels)) {
      this.error = 'HD and Levels must be numbers.'
      return
    }
    for (let i = 1; i <= levels; i++) {
      this.addClass(hd, this.characterClasses.length + 1)
    }
    this.activeHD = undefined
    this.activeLevels = undefined
    this.hasDraconic = false
    this.calculateHP()
  }

  addClass(hd: number, level: number) {
    let formula = `${(Math.floor(hd / 2) + 1)}[AVG HD] + ${this.conMod}[CON]`
    if (this.characterClasses.length === 0) {
      formula = `${hd}[MAX HD] + ${this.conMod}[CON]`
    }
    if (this.hasDraconic && hd === 6) {
      formula += ' + 1[Draconic]'
    }
    this.characterClasses.push({
      hd,
      level,
      formula,
      isDraconic: this.hasDraconic
    })
  }

  calculateHP() {
    this.error = ''
    if (isNaN(this.conMod)) {
      this.error = 'CON Mod is empty.'
      return
    }
    const totalLevels = this.characterClasses.length
    if (totalLevels <= 0) {
      this.error = 'No classes added'
      return
    }
    let totalHP = 0
    this.characterClasses.forEach((characterClass, i) => {
      if (i === 0) {
        totalHP = characterClass.hd + this.conMod
      } else {
        const hpAdded = (Math.floor(characterClass.hd / 2) + 1) + this.conMod
        totalHP += hpAdded
      }

      if (characterClass.isDraconic) {
        totalHP += 1
      }
    })
    if (this.hasToughFeat) {
      totalHP += 2 * totalLevels
    }
    if (this.isHillDwarf) {
      totalHP += totalLevels
    }
    if (this.isLegacy) {
      totalHP += totalLevels - 1
    }
    this.totalHP = totalHP
  }

}
