<mat-card class="card" fxFlex>
  <div fxFlex fxLayout="column">
    <mat-card-title>
      <div>Spell Casting Service Calculator</div>
    </mat-card-title>
    <mat-card-content fxLayout="column" fxLayoutGap="10px">
      <mat-form-field>
        <mat-select [(value)]="selectedSpellLevel" (selectionChange)="onCalculateCost()">
          <mat-option *ngFor="let spellLevel of spellLevels" [value]="spellLevel">{{ spellLevel.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field fxFlex>
          <input matInput type="number" [(ngModel)]="materialCost" (keyup)="onCalculateCost()" placeholder="Material Cost">
          <span matSuffix>&nbsp;GP</span>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="number" [(ngModel)]="consumedCost" (keyup)="onCalculateCost()" placeholder="Consumed Material Cost">
          <span matSuffix>&nbsp;GP</span>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <div fxFlex></div>
      <div *ngIf="serviceCost">Cost: {{ serviceCost }} GP</div>
    </mat-card-actions>
  </div>
</mat-card>
