<mat-card class="card">
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title>Rules</mat-card-title>
    <mat-card-content fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="50" fxLayout="column" fxLayoutGap="5px">
        <h3>Races and Classes</h3>
        <div>
          <div>Any race or class from our Approved Materials list is allowed.</div>
          <div>Tasha's Cauldron of Everything subclass Optional Features allowed.</div>
          <div>No customizing your Origin, Skills, or Subclasses (outlined from TCE).</div>
          <div>Ancestral Lineage only gives 2 skill proficiencies.</div>
          <div>No customizations such as Dark Gifts, Hollow One, or Heroic Gifts.</div>
        </div>
        <div><hr></div>
        <h3>Roll Stats on Discord</h3>
        <ol>
          <li>All characters should have a token image. <a href="http://rolladvantage.com/tokenstamp/">TokenStamp Creator</a> is an option.</li>
          <li>Use the <code>!create Character Name</code> command in <code class="channel-name">#character-creation</code>.</li>
          <li>Select 1️⃣, 2️⃣, or 3️⃣ in <code class="channel-name">#creation-rolls</code> for the column you choose.</li>
          <li>Finish your character and get it ready for approval; react to message in <code class="channel-name">#creation-rolls</code> with ✅.</li>
          <li class="note">DMs will notify you and mark your rolls with 👍 when character is approved.</li>
        </ol>
        <div><hr></div>
        <h3>Levels and Experience</h3>
        <div>
          <div>All characters start at level 4, 2700XP.</div>
          <div>All characters use Average HP.</div>
          <div><b class="accent">Starting Equipment:</b> All characters choose either Starting Equipment plus anything provided by the background or Starting Gold based on their class.</div>
        </div>
        <div><b class="accent">Character Sheets:</b> All players get 4 character sheets for free. After all 4 move above Tier 1, 2 free sheets are provided which are locked to Tier 1. Once these 2 free sheets reach 6,499 XP, they do not gain any more XP or GP. Instead, only minor healing potions and standard ammunition are replenished.</div>
        <div><hr></div>
        <h3>Background and Alignment</h3>
        <div>
          <div><b class="accent">Background:</b> Choose or create a background from the Allowed Material options.</div>
          <div><b class="accent">Alignment:</b> Choose an alignment. Although evil alignment is allowed, your first duty is to Ruthgar's.</div>
          <div><b class="accent">Dieties:</b> Clerics must worship a single, specific deity but aren't limited to the Domains recommeonded for their deity. Other characters are not required to have a deity.</div>
        </div>
        <div><hr></div>
        <h3>Equipment and Shop</h3>
        <div>
          <div>The shop is located <a href="https://docs.google.com/spreadsheets/d/1qOyjS5lyQultqXfI2LelEeIW9BqE87R_KLIbKn5OX7I/edit?usp=sharing">here</a>.</div>
          <div>All <a href="https://docs.google.com/spreadsheets/d/1qOyjS5lyQultqXfI2LelEeIW9BqE87R_KLIbKn5OX7I/edit?usp=sharing">shop</a> purchases and sales must be posted in <code class="channel-name">#purchases</code>.</div>
          <div>Shop buys back items at 80% of the purchased price.</div>
          <div>Any rewards received from missions cannot be sold in the same tier they were earned.</div>
        </div>
        <div><hr></div>
        <h3><a class="link-help" (click)="onOpenFlankingDialog()">Flanking (Click here for visualization)</a></h3>
        <div>
          <div><b class="accent">Number of Allies:</b> Must have a minimum of 2 allies. Ally requirement increase by 2 per a target's size above medium (a Huge creature needs 6 allies total to flank).</div>
          <div><b class="accent">Straight lines:</b> Each flanker must have an ally on the opposite side in a straight line through the center of the target.</div>
          <div><b class="accent">Triangle of Death:</b> A creature in a isosceles triangle formation with 2 other allies who are already flanking is also considered flanking.</div>
          <div><b class="accent">DM Discretion:</b> Any DM may choose to ignore one or all of the rules above. They will make it clear before a session.</div>
        </div>
      </div>
      <div fxFlex="50" fxLayout="column" fxLayoutGap="5px">
        <h3>Tiers and Attunement</h3>
        <div>
          <div>Ruthgar's follow the <a href="https://5etools.ruthgars.com/quickreference.html#bookref-quick,0,tiers%20of%20play,0" target="_blank">Tiers of Play</a> rules.</div>
          <div>All characters have 3 Attunement Slots.</div>
          <div>Any item attuned to a creature under your control (familiars, beast companions, simulacrums, etc.) counts against your character’s limit of attuned items for that tier.</div>
        </div>
        <div>
          <b class="accent">Tier 1 and Tier 2:</b>
          <div>- Anything that summons a minion requires attunement.</div>
          <div>- All Wands require attunement.</div>
        </div>
        <div><hr></div>
        <h3>Magic Items, Potions, and Scrolls</h3>
        <div>
          <div>A creature can only benefit from one non-healing potion at any time.</div>
          <div>A player character may not possess two or more wondrous items of the same type; with the following exception:</div>
          <div>A player character can possess up to two Guild Charms, Scrolls, or Spell Gems.</div>
          <div>A player character can use one Tome/Manual for each Ability Score.</div>
        </div>
        <div><hr></div>
        <h3>Spells and Effects</h3>
        <div>Simulacrums can’t cast simulacrum, or any spell that duplicates its effects.</div>
        <div>Spells or effects that create items can only create items that are otherwise available for purchase.</div>
        <div>Spells cast by using a magic item may be counterspelled.</div>
        <div><hr></div>
        <h3>Death and Retirement</h3>
        <div>
          <div>A Tier 1 character may be retired but must re-use the same stats roll.</div>
          <div>A Tier 2 or higher character may be retired for a character at the lowest level one tier below.</div>
          <div>A Tier 2 or higher retired character's stats can be re-used or you may roll new stats.</div>
          <div>If your character honorably dies you can remake another at the lowest level of the same tier.</div>
          <div>Either death or retirement, post in <code class="channel-name">#graveyard</code> and include the character name; if being retired, include the new character's name and indicate if the stats are being reused.</div>
          <div>Either death of retirement, your replacement character starts with extra gold depending on their tier, in addition to the items/gold granted by their race, class, background, etc.</div>
        </div>
        <div>
          <div><b class="accent">Additional Starting Gold:</b> Tier 2: 1,000GP; Tier 3: 8,000GP; Tier 4: 20,000GP </div>
        </div>
        <div><hr></div>
        <h3>Disease and Curses</h3>
        <div>Any permanent disease or curse will be removed at the end of a session by a high ranking Ruthgar's member for free.</div>
        <div><hr></div>
        <h3>Logging</h3>
        <div>
          <div>All activity must be logged in the relevant Discord channel.</div>
          <div><b class="accent">Character:</b> Starting gold, learning spells, and spell casting services belong in <code class="channel-name">#character-rolls</code>.</div>
          <div><b class="accent">Downtime:</b> Downtime activities belong in <code class="channel-name">#downtime</code>.</div>
          <div><b class="accent">Items:</b> Item rolls, reparing, and crafting rolls belong in <code class="channel-name">#item-rolls</code>.</div>
          <div><b class="accent">Purchases:</b> All purchase and sales belong in <code class="channel-name">#purchases</code>.</div>
          <div><b class="accent">Retirement/Death:</b> All death and retirement belong in <code class="channel-name">#graveyard</code>.</div>
        </div>
        <div>
          <div>A player can view their stats for a character in <code class="channel-name">#bot-commands</code> using the <code>!stats Character Name</code> command.</div>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>