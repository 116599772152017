import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlankingDialogComponent } from './flanking-dialog/flanking-dialog.component';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  onOpenFlankingDialog() {
    this.dialog.open(FlankingDialogComponent, {
      height: '100vh'
    });
  }

}
