export const VALID_SOURCES = [
  'AI',
  'ATLAS',
  'DMG',
  'EEPC',
  'EGW',
  'ERLW',
  'GRR',
  'MOT',
  'MTF',
  'PHB',
  'SCAG',
  'TTP',
  'VGM',
  'WGE',
]
