import { Component, OnInit } from '@angular/core'
import { Store, Select } from '@ngxs/store'
import { VALID_CLASSES } from 'src/app/utils/valid-classes'
import * as _ from 'lodash'
import { VALID_SOURCES } from 'src/app/utils/valid-sources'
import { CharacterBuilderState } from 'src/app/states/character-builder/charter-builder.state'
import { Observable } from 'rxjs'
import { Level } from 'src/app/states/character-builder/character-builder.model'
import { SelectCharacterClass } from 'src/app/states/character-builder/character-builder.actions'

@Component({
  selector: 'app-class-picker',
  templateUrl: './class-picker.component.html',
  styleUrls: ['./class-picker.component.scss']
})
export class ClassPickerComponent implements OnInit {
  @Select(CharacterBuilderState.levels)
  levels$: Observable<Array<Level>>
  levels: Array<Level>
  startingLevel: Level
  totalLevels: any
  levelsToAdd: number

  availableClasses: any[]
  selectedClass: any
  validClasses: any[]
  characterClass: any
  validSubClasses: any[]
  characterSubClass: any

  characterAddError: Array<string>

  constructor(
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.availableClasses = VALID_CLASSES
    this.levelsToAdd = 1
    this.characterAddError = []
    this.characterSubClass = undefined
    this.levels$.subscribe(levels => {
      this.setLevels(levels)
    })
  }

  setLevels(levels: any) {
    this.levels = levels
    this.totalLevels = {}
    this.startingLevel = undefined
    for (const level of this.levels) {
      if (!this.startingLevel) {
        this.startingLevel = level
      }
      if (!this.totalLevels.hasOwnProperty(level.class.name)) {
        this.totalLevels[level.class.name] = 1
      } else {
        this.totalLevels[level.class.name] += 1
      }
    }
    this.totalLevels = this.totalLevels
  }

  onClassSelected(characterClass: any) {
    this.selectedClass = characterClass
    this.validClasses = this.getOnlyAllowedMaterial(this.selectedClass.class)
    this.characterClass = this.validClasses[0] // Set Default to First
    this.validSubClasses = this.getOnlyAllowedMaterial(this.characterClass.subclasses)
    this.characterSubClass = undefined
  }

  getOnlyAllowedMaterial(list: any) {
    return _.filter(list, (characterClass) => {
      if (VALID_SOURCES.indexOf(characterClass.source) !== -1) {
        return true
      }
      return false
    })
  }

  onAddClass() {
    this.characterAddError = []
    const totalLevels = this.totalLevels[this.characterClass.name] || 0
    if (this.characterSubClass) {
      if ((totalLevels + this.levelsToAdd) < 3) {
        this.characterAddError.push('Subclass requires at least 3 levels.')
        return false
      }
    }
    this.store.dispatch(
      new SelectCharacterClass(this.selectedClass, this.characterClass, this.characterSubClass, this.levelsToAdd, totalLevels)
    )
  }
}
