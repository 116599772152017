<div class="component-section" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
  <div>
    <mat-card class="card-form">
      <div fxFlex fxLayout="column" fxLayoutGap="10px">
        <mat-card-title>Ruthgar's Loot Generator</mat-card-title>
        <div fxLayout="column" fxLayoutGap="5px">
          <mat-form-field>
            <mat-select
              [(ngModel)]="selectedTier"
              placeholder="Select a Tier"
              aria-label="Select a Tier"
              matInput>
              <mat-option *ngFor="let tier of tiers" [value]="tier">{{ tier.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input matInput [ngModel]="playerCount" (ngModelChange)="onPlayerCountChanged($event)" placeholder="Number of Players" type="number">
          </mat-form-field>
          <button mat-raised-button color="accent" (click)="onGenerate()">Generate</button>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="scrollable" fxLayout="column" fxLayoutGap="10px">
    <mat-card class="card-loot" *ngFor="let loot of generatedLoot; let generatedLootIndex = index;">
      <mat-card-title>{{ loot.tier }}</mat-card-title>
      <div fxLayout="row">
        <div>GP: {{ loot.gp }} ({{ loot.gp/playerCount | number:'1.0-0' }} gp per player)</div>
        <div fxFlex></div>
        <div>Items Total: {{ loot.itemsTotalGP }}</div>
      </div>
      <div *ngIf="loot.items.length > 0">Magic Items:</div>
      <div class="item-row" *ngFor="let item of loot.items; let lootItemIndex = index;" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-icon class="actionable" (click)="onSellItem(generatedLootIndex, lootItemIndex, item[SheetHeaders.PRICE])">clear</mat-icon>
        <div class="item-name">{{ item[SheetHeaders.NAME] }}</div>
        <div class="item-rarity" fxLayoutAlign="center center">({{ item[SheetHeaders.RARITY] }})</div>
        <div fxFlex></div>
        <div class="item-price">{{ item[SheetHeaders.PRICE] }} GP</div>
      </div>
    </mat-card>
  </div>
</div>
