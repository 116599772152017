<mat-card>
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title fxLayoutAlign="space-between">
      Donator Material
      <a href="https://ko-fi.com/ruthgars" target="_blank"><button mat-raised-button color="accent">Donate</button></a>
    </mat-card-title>
    <mat-card-content>
      <div class="list" fxLayoutGap="10px">
        <div><a href="https://drive.google.com/file/d/1zIVtETG6chAH41_-Tcg8cMp8VkwnN-4L/view">Juggernaut Class 2.0</a></div>
        <div><a href="https://drive.google.com/file/d/1eKsKBtF81w97TQSf0-LwSSITGQLnIxwj/view">Surgebinder 3.0</a></div>
        <div><a href="https://www.gmbinder.com/share/-MapD2wVQh6DhvY07xIW">Soul Binder .8 (Redux)</a></div>
        <div><a href="https://drive.google.com/file/d/1CoECTjuRNqsxZxnmS3wh3DmKum4VmPlF/view">Seeker 4.0</a></div>
        <div><a href="https://www.gmbinder.com/share/-M-WtrKeZNFdEXq0MKXw">Occultist 1.1</a></div>
        <div><a href="https://www.gmbinder.com/share/-M0ZVK6ndhFyImQPF_aJ">Savant 4.4.1</a></div>
        <div><a href="https://www.gmbinder.com/share/-M0ncGWa5j9oDFwilwPl">Roguish Archetypes 1.1.0 (/u/laserllama)</a></div>
        <div><a href="https://drive.google.com/file/d/14tpSz6nKAPapi3oMbBroTJ3zAmg7NXnr/view">Stargazer 2.1</a></div>
      </div>
    </mat-card-content>
    <div class="donate-note">
      <div><b>$10</b> opens access homebrew material.</div>
      <div><b>$15</b> activates 2 additional character slots.</div>
      <div><b>$20/month</b> Beta Testing/Homebrew submission.</div>
      <div>PM @lighthazard with any questions.</div>
    </div>
  </div>
</mat-card>
