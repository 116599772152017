import { Component, OnInit, Inject } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-faq-dialog',
  templateUrl: './faq-dialog.component.html',
  styleUrls: ['./faq-dialog.component.scss']
})
export class FaqDialogComponent implements OnInit {
  questionFormControl = new FormControl('', [
    Validators.required,
  ])

  answerFormControl = new FormControl('', [
    Validators.required,
  ])

  constructor(
    private dialogRef: MatDialogRef<FaqDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.questionFormControl.setValue(data.question)
    this.answerFormControl.setValue(data.answer)
  }

  ngOnInit(): void {
  }

  updateFAQ() {
    this.dialogRef.close({
      question: this.questionFormControl.value,
      answer: this.answerFormControl.value,
    })
  }

  onCancel() {
    this.dialogRef.close()
  }

}
