import { AngularFireDatabase } from '@angular/fire/database'
import { Component, OnInit } from '@angular/core'
import * as moment from 'moment'

@Component({
  selector: 'app-character-info',
  templateUrl: './character-info.component.html',
  styleUrls: ['./character-info.component.scss']
})
export class CharacterInfoComponent implements OnInit {
  messages: any[]
  username: string
  discordUsername: string
  characterName: string
  characterNameAlt: string
  totalXP: any
  totalGP: any
  totalGames: any
  weeksPlayed: any
  downtimeDays: any

  constructor(
    firestore: AngularFireDatabase
  ) {
    firestore.list('sessionStats/messages').valueChanges().subscribe(messages => {
      this.messages = messages
    })
  }

  ngOnInit(): void {
  }
  searchCharacter() {
    this.totalXP = 0
    const allPlayerNames = []
    let username = this.username
    if (username) {
      const names = username.split('|').map(u => u.trim())
      username = names.join('|')
      username = username.replace('@', '')
      allPlayerNames.push(username)
    }
    let discordName = this.discordUsername
    if (this.discordUsername) {
      const names = username.split('|').map(u => u.trim().split('#')[0])
      discordName = names.join('|')
      allPlayerNames.push(discordName)
    }

    const allCharacterNames = []
    let characterName = this.characterName
    if (characterName) {
      const names = characterName.split('|').map(u => u.trim())
      characterName = names.join('|')
      allCharacterNames.push(characterName)
    }
    let characterNameAlt = this.characterNameAlt
    if (this.characterNameAlt) {
      const names = characterName.split('|').map(u => u.trim())
      characterNameAlt = names.join('|')
      allCharacterNames.push(characterNameAlt)
    }

    const playerNames = `(${allPlayerNames.join('|')})`
    const characterNames = `(${allCharacterNames.join('|')})`
    if (playerNames && characterNames) {
      this.searchMessages(playerNames, characterNames)
    }
  }

  searchMessages(player: string, name: string) {
    const relevantMessages = this.messages.filter((m, i) => {
      const playerRegex = new RegExp(player.trim(), 'i')
      const regex = new RegExp(name.trim(), 'i')
      return playerRegex.test(m.content) && regex.test(m.content)
      // return regex.test(m.content)
    })

    this.totalXP = this.totalXPForCharacter(relevantMessages)
    this.totalGP = this.totalGPForCharacter(relevantMessages)
    // console.log('Total XP:', this.totalXP)
    // console.log('Level3', this.totalXP + 900)
    // console.log('Level4', this.totalXP + 2700)
    // console.log('Total GP:', this.totalGP)
    this.totalGames = this.gamesPlayed(relevantMessages)
    // console.log('Total Games:', this.totalGames)
  }

  gamesPlayed(relevantMessages: Array<any>) {
    let totalGamesPlayed = 0
    const weekCounter: any = {}
    let firstGame: any
    relevantMessages.forEach((v, i) => {
      const timestamp = v.timestamp
      const time = moment(timestamp)
      if (!firstGame) {
        firstGame = time
      }
      const week = time.isoWeek()
      const year = time.year()
      totalGamesPlayed++

      const key = `${year} ${week}`
      if (key in weekCounter) {
        weekCounter[key]++
      } else {
        weekCounter[key] = 0;
      }

      // console.log('timestamp', timestamp)
      // console.log("\n", v.content)
    })

    let hasViolation = false
    Object.keys(weekCounter).forEach(k => {
      if (weekCounter[k] > 3) {
        hasViolation = true
      }
    })

    this.displayDowntimeDays(firstGame)


    if (hasViolation) {
      // Spit out the relevant content
      // console.log(relevantMessages)
      return totalGamesPlayed + ' (HAS VIOLATIONS)'
    }
    return totalGamesPlayed + ' (No Violations)'
  }

  totalXPForCharacter(relevantMessages: Array<any>) {
    const regex = new RegExp('^\s*(xp|exp)', 'i')
    const total = this.getTotalFromLines(relevantMessages, regex)
    return total
  }

  totalGPForCharacter(relevantMessages: Array<any>) {
    const regex = new RegExp('^\s*(gp|gold)', 'i')
    const total = this.getTotalFromLines(relevantMessages, regex)
    return total
  }

  getTotalFromLines(relevantMessages: Array<any>, regex: RegExp) {
    let total = 0
    relevantMessages.forEach((v, i) => {
      const content = v.content
      // console.log(content, "\n\n")
      const lines = content.split('\n')
      lines.forEach((line: string) => {
        if (regex.test(line)) {
          total += this.extractNumber(line)
        }
      })
    })
    return total
  }

  extractNumber(line: string) {
    const regex = new RegExp(/\d+/g)
    const normalizedLine = line.replace(/,\s*/, '').replace(/k/i, '000')
    const match = normalizedLine.match(regex)
    if (match) {
      return parseInt(match[0], 10)
    }
    return 0
  }

  displayDowntimeDays(firstGame: moment.Moment) {
    if (!firstGame) {
      return
    }
    const today = moment()
    const weeks = Math.abs(firstGame.clone().startOf('day').diff(today.clone().startOf('day'), 'week'))
    this.weeksPlayed = weeks
    this.downtimeDays = weeks * 5
  }
}
