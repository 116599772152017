export class Login {
  static readonly type = '[DM] Login'
  constructor(public email: string, public password: string) {}
}

export class Logout {
  static readonly type = '[DM] Logout'
}

export class SetLoggedIn {
  static readonly type = '[DM] Set Logged In'
  constructor(public user: any) {}
}

export class AddFAQ {
  static readonly type = '[DM] Add FAQ'
  constructor(public question: string, public answer: string) {}
}

export class EditFAQ {
  static readonly type = '[DM] Edit FAQ'
  constructor(public faq: any, public question: string, public answer: string) {}
}
