<mat-card>
  <div fxFlex fxLayout="column" fxLayoutGap="10px">
    <mat-card-title>Allowed Material</mat-card-title>
    <mat-card-content>
      <h3>Official</h3>
      <div class="list" fxLayoutGap="10px">
        <div>Acquisitions Incorporated</div>
        <div>Curse of Strahd</div>
        <div>Dungeons Master's Guide</div>
        <div>Eberron: Rising from the Last War</div>
        <div>Elemental Evil Player’s Companion</div>
        <div>Explorers Guide to Wildemount</div>
        <div>Fizban's Treasury of Dragons</div>
        <div>Guildmasters' Guide to Ravnica</div>
        <div>Lost Laboratory of Kwalish</div>
        <div>Monster Manual</div>
        <div>Mordenkainen's Tome of Foes</div>
        <div>Mythical Odyssey Of Theros</div>
        <div>Player's Handbook</div>
        <div>Sword Coast Adventurer's Guide</div>
        <div>Strixhaven, Curriculum of Chaos</div>
        <div>Tasha's Cauldron of Everything</div>
        <div class="ranger-note">&nbsp;&nbsp;No Customizing Your Origin, Skills, or Subclasses.</div>
        <div>The Tortle Package</div>
        <div>Van Richten's Guide to Ravenloft</div>
        <div>Volo's Guide to Monsters</div>
        <div>Wayfinder’s Guide to Eberron</div>
        <div>Wilds Beyond Witchlight</div>
        <div>Xanathar's Guide to Everything</div>
      </div>
      <h3>UA/Homebrew</h3>
      <div class="list" fxLayoutGap="10px">
        <div><a href="https://drive.google.com/file/d/1aUB795FHDTHTlpAFKReog_zeRm_cLbHW/view?usp=sharing">Complete Warmage (Mage Hand Press)</a></div>
        <div class="ranger-note">&nbsp;&nbsp;House of Cards and Dice are not allowed.</div>
        <div><a href="https://drive.google.com/file/d/1iK0JN6OcqBJhqAGhtBBwFsIF5Y6bqnnt/view">Gunsmith Class</a></div>
        <div><a href="https://drive.google.com/file/d/102x0NLPWaeHi71z-OZFH6pCuqhj3-2l8/view">Warden</a></div>
        <div><a href="https://drive.google.com/file/d/1QEO_T3WPeOPLYP8fBsmn88nFRdFuITLP/view">Witch</a></div>
        <div>
          Grim Hollow 
          (<a href="https://5etools.ruthgars.com/spells.html#assisted%20aim_grimhollow,flstsource:ai=0~atlas=0~eepc=0~egw=0~ggr=0~idrotf=0~mot=0~phb=0~scag=0~tce=0~vgm=0~mfov%3awm=0~xge=0~ruthgarscodex=0,flopsource:extend">Spells</a> & <a href="https://5etools.ruthgars.com/feats.html#thrown%20weapon%20master_grimhollow,flstsource:erlw=0~phb=0~tce=0~xge=0~mtf=0~taldorei=0,flopsource:extend">Feats</a>)
        </div>
        <div><a href="https://media.wizards.com/2016/dnd/downloads/UA_RevisedRanger.pdf">Revised Ranger</a></div>
        <div><a href="https://drive.google.com/file/d/1gsYmurLN9Ubr8oRI40XGBzIdsAYBWx7g/view?usp=sharing">The Griffon's Saddlebags</a></div>
        <div><a href="https://www.google.com/amp/s/amp.reddit.com/r/UnearthedArcana/comments/a7netq/all_the_lights_in_the_sky_are_stars_an/">All the Lights in the Sky Are Stars</a></div>
        <div><a href="https://drive.google.com/file/d/1Khe6lAeExnOiXCvGOI26JlXREzlxNJ-K/view?usp=sharing">TalDorei Campaign Setting</a></div>
        <div><a href="https://www.gmbinder.com/share/-LW4agTNJcbwe6kSv4H2">Warlord 1.4.2 (KibblesTasty)</a></div>
        <div><a href="https://drive.google.com/file/d/1FzUhljw5RlVOYIiT79dQ8gQnQLnDvk1n/view?usp=sharing">Blade Dancer 1.01</a></div>
      </div>
    </mat-card-content>
  </div>
