<mat-card class="race-picker">
  <mat-card-title>Race</mat-card-title>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="10px" *ngIf="filteredRaces">
      <form>
        <mat-form-field fxFlex>
          <input type="text"
            placeholder="Pick a Race"
            aria-label="Race"
            matInput
            [formControl]="raceFormControl"
            [matAutocomplete]="auto">
          <mat-autocomplete 
            #auto="matAutocomplete"
            [displayWith]="displayRaceFn"
            (optionSelected)='selectRace($event.option.value)'
          >
            <mat-option *ngFor="let race of filteredRaces | async" [value]="race" class="infoText">
              <div>{{ race.name }}</div>
              <div fxFlex></div>
              <div>{{ race.source }}</div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <div *ngIf="race$ | async as race" fxLayout="column" fxLayoutGap="10px">
        <div>{{ race?.name }}</div>
        <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="start center">
          <ng-container *ngIf="race?.ability">
            <div *ngFor="let ability of race?.ability[0] | keyvalue">
              <div *ngIf="ability.key !== 'choose'; else chooseText" fxLayout="row">
                {{ ability.key | uppercase}}: <span *ngIf="ability.value > 0">+</span>{{ ability.value }}
              </div>
              <ng-template #chooseText>
                <div fxFlex fxLayout="row" fxLayoutGap="5px">
                  <!-- <div *ngIf="ability.value.count">Any {{ ability.value.count }} unique.</div> -->
                  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="left">
                    <div *ngFor="let count of [].constructor(ability.value.count); let i = index">
                      <mat-form-field class="as-input">
                        <mat-select placeholder="Ability Score" (selectionChange)="onChooseChoice($event)">
                          <mat-option *ngFor="let chooseAbility of ability.value.from" [value]="chooseAbility">
                            {{ chooseAbility | uppercase }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </div>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title fxLayout="column" fxLayoutGap="5px" class="infoText">
              Features and Traits
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let entry of race?.entries" class="infoText">
            <b>{{ entry.name }}:</b> 
            <span *ngFor="let description of entry.entries">
              {{ description }}
            </span>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </mat-card-content>
</mat-card>