import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CharacterBuilderState } from 'src/app/states/character-builder/charter-builder.state';
import { Observable } from 'rxjs';
import { Level } from 'src/app/states/character-builder/character-builder.model';

@Component({
  selector: 'app-spell-picker',
  templateUrl: './spell-picker.component.html',
  styleUrls: ['./spell-picker.component.scss']
})
export class SpellPickerComponent implements OnInit {
  @Select(CharacterBuilderState.levels)
  levels$: Observable<Array<Level>>
  startingLevel: Level
  spellCastingLevels: Array<any>

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.spellCastingLevels = []
    this.levels$.subscribe(levels => {
      for (const level of levels) {
        if (!this.startingLevel) {
          this.startingLevel = level
        }
        if (level.class.spellcastingAbility  || (level.subclass && level.subclass.spellcastingAbility)) {
          // Push into spell casting levels to calculate total spell casting slots and choices
          this.spellCastingLevels.push(level)
        }
      }
    })
  }

}
