import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-casting-service-calculator',
  templateUrl: './casting-service-calculator.component.html',
  styleUrls: ['./casting-service-calculator.component.scss']
})
export class CastingServiceCalculatorComponent implements OnInit {
  selectedSpellLevel: any
  serviceCost: any
  materialCost: any
  consumedCost: any
  spellLevels = [
    {
      level: 0,
      name: 'Cantrip',
    },
    {
      level: 1,
      name: 'Spell Level 1',
    },
    {
      level: 2,
      name: 'Spell Level 2',
    },
    {
      level: 3,
      name: 'Spell Level 3',
    },
    {
      level: 4,
      name: 'Spell Level 4',
    },
    {
      level: 5,
      name: 'Spell Level 5',
    },
    {
      level: 6,
      name: 'Spell Level 6',
    },
    {
      level: 7,
      name: 'Spell Level 7',
    },
    {
      level: 8,
      name: 'Spell Level 8',
    },
    {
      level: 9,
      name: 'Spell Level 9',
    },
  ]

  constructor() { }

  ngOnInit(): void {
    this.selectedSpellLevel = this.spellLevels[5]

    this.onCalculateCost()
  }

  onCalculateCost() {
    const level = this.selectedSpellLevel.level
    const materialCost = this.materialCost ? this.materialCost : 0
    const consumedCost = this.consumedCost ? this.consumedCost : 0
    this.serviceCost = Math.floor((level ** 2) * 10 + (2 * consumedCost) + (.1 * materialCost))
  }

}
