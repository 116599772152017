import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { CharacterBuilderState } from 'src/app/states/character-builder/charter-builder.state';
import { Observable } from 'rxjs';
import { Level } from 'src/app/states/character-builder/character-builder.model';
import { ResetLevels } from 'src/app/states/character-builder/character-builder.actions';

@Component({
  selector: 'app-character-levels',
  templateUrl: './character-levels.component.html',
  styleUrls: ['./character-levels.component.scss']
})
export class CharacterLevelsComponent implements OnInit {
  @Select(CharacterBuilderState.levels)
  levels$: Observable<Array<Level>>

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
  }

  onCharacterReset() {
    this.store.dispatch(new ResetLevels())
  }

}
