import { CharacterBuilderState } from 'src/app/states/character-builder/charter-builder.state'
import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { Select, Store } from '@ngxs/store'
import { VALID_SOURCES } from 'src/app/utils/valid-sources'
import {map, startWith} from 'rxjs/operators'
import * as races from 'src/app/data/races.json'
import * as _ from 'lodash'
import { SelectRace, AddRaceCustomAbility } from 'src/app/states/character-builder/character-builder.actions'

@Component({
  selector: 'app-race-picker',
  templateUrl: './race-picker.component.html',
  styleUrls: ['./race-picker.component.scss']
})
export class RacePickerComponent implements OnInit {
  @Select(CharacterBuilderState.race)
  race$: Observable<any>

  raceFormControl = new FormControl()
  validRaces: Array<any>
  filteredRaces: Observable<any>

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    const raceSnapShot = this.store.selectSnapshot(CharacterBuilderState.race)
    this.validRaces = this.getValidRaces()
    this.filteredRaces = this.raceFormControl.valueChanges
    .pipe(
      startWith(raceSnapShot || ''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.validRaces.slice())
    )
    this.raceFormControl.setValue(raceSnapShot)
  }

  displayRaceFn(race: any) {
    return race && race.name ? race.name : '';
  }

  getValidRaces(): Array<any> {
    const playableRaces = races.race.filter((race) => {
      if (
        race.srd ||
        VALID_SOURCES.indexOf(race.source) !== -1
        && (!race.traitTags || race.traitTags && race.traitTags.indexOf('NPC Race') === -1)
      ) {
        return true
      }
      return false
    })

    return this.buildValidRaces(playableRaces)
  }

  buildValidRaces(playableRaces: Array<any>) {
    const validRaces = _.flatMap(playableRaces, (playableRace) => {
      if (playableRace.subraces && playableRace.subraces.length > 0) {
        const subRaces = []
        for (const subRace of playableRace.subraces) {
          if (subRace.source && VALID_SOURCES.indexOf(subRace.source) === -1 && !subRace.srd) {
            continue
          }
          const playableSubRace = _.cloneDeep(playableRace)
          if (subRace.name) {
            playableSubRace.name = playableRace.name + ' (' + subRace.name + ')'
          }
          if (subRace.source) {
            playableSubRace.source = subRace.source
          }
          if (subRace.ability && subRace.ability.length > 0) {
            if (!playableSubRace.ability) {
              playableSubRace.ability = []
            }
            playableSubRace.ability[0] = { ...playableSubRace.ability[0], ...subRace.ability[0] }
          }
          subRaces.push(playableSubRace)
        }
        return subRaces
      } else {
        return playableRace
      }
    })
    return validRaces
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.validRaces.filter(option => option.name.toLowerCase().indexOf(filterValue) !== -1);
  }

  selectRace(race: any) {
    this.store.dispatch(new SelectRace(race))
  }

  onChooseChoice(event: any) {
    const ability: string = event.value
    this.store.dispatch(new AddRaceCustomAbility(ability.toUpperCase()))
  }
}
