import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './app.component'
import { HomepageComponent } from './homepage/homepage.component'
import { CharacterBuilderComponent } from './character-builder/character-builder.component'
import { DmLandingComponent } from './dm-landing/dm-landing.component'
import { FaqComponent } from './faq/faq.component'
import { DowntimeComponent } from './downtime/downtime.component'
import { HpCalculatorComponent } from './hp-calculator/hp-calculator.component'
import { AllowedMaterialComponent } from './allowed-material/allowed-material.component'
import { DonatedMaterialComponent } from './donated-material/donated-material.component'
import { RulesComponent } from './rules/rules.component'
import { LootGeneratorComponent } from './loot-generator/loot-generator.component'

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'allowed-material', component: AllowedMaterialComponent },
  { path: 'character-builder', component: CharacterBuilderComponent },
  { path: 'creation-rules', component: RulesComponent },
  { path: 'donate', component: DonatedMaterialComponent },
  { path: 'dm', component: DmLandingComponent },
  { path: 'downtime', component: DowntimeComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'hp-calculator', component: HpCalculatorComponent },
  { path: 'lootgen', component: LootGeneratorComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
