import { Component, OnInit } from '@angular/core'
import * as  races from 'src/app/data/races.json'

@Component({
  selector: 'app-character-builder',
  templateUrl: './character-builder.component.html',
  styleUrls: ['./character-builder.component.scss']
})

export class CharacterBuilderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
